import React from 'react'
import {Link} from 'react-router-dom'  

import { BsLinkedin, BsGithub} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'
import {BiPhoneCall} from 'react-icons/bi'

import './Footer.css'

const Footer = () => {

  return (
    <div className='footer'>
      <div className='socialMedia'>
       
        <Link to="https://www.linkedin.com/in/surindersinghs/">
          <BsLinkedin  />
        </Link>
       <Link to="https://github.com/SurinderSinghSaby">
        <BsGithub />
       </Link>
       <a href={`tel:${5204614333}`}>
          <BiPhoneCall />
        </a>
        <a href="mailto:sabysurinder@gmail.com">
          <AiOutlineMail />
        </a>

      
        
      </div>
      <p> &copy; 2023 surindersingh.net</p>
    </div>
  )
}

export default Footer