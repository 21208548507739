import React from 'react'

import project1 from '../assets/project1.png'
import project2 from '../assets/project2.png'

export const ProjectList = [
    {
        id: 0,
        title: 'Jobflex',
        tech: 'React, Exress, NodeJs, MongoDB',
        http: 'https:/jobflex.surindersingh.net/',
        image: project1,
        description: 'Jobflex is a job tracking application that allows users to keep track of their job applications and the status of each application.'
     },
     {
        id: 1,
        title: 'IdeaShare',
        tech: 'NextJs, MongoDB',
        http: 'https://daily-post-qh22izkaz-surindersinghsaby.vercel.app/',
        image: project2,
        description: 'IdeaShare is a social media application that allows users to share their ideas and thoughts with other users.'
     }
]