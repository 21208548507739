import React from 'react'

import '../../pages/Home.css'

const Skills = (props) => {
  return (
    <li className='item'>
        <h2>{props.heading}</h2>
        <span>{props.info}</span>
    </li>
  )
}

export default Skills