import React from 'react'
import {useNavigate} from 'react-router-dom'

import './ProjectItem.css'
import '../../pages/Projects.css'

const ProjectItem = (props) => {

const navigate = useNavigate();

const handleNavigation = () => {
    window.location.href = props.http;
  };

  return (
    <div className='projectItem'>
        <h1  onClick={handleNavigation}>
            {props.title}
        </h1>
        <h2>{props.tech}</h2>
        <div style={{backgroundImage: `url(${props.image})`}} 
        className="bgImage" 
         onClick={handleNavigation}>
            {/*<img src={props.image} className="pimage"></img>*/}
        </div>
        <button onClick={() => {navigate("" + props.id)}}>View Description</button>

    </div>
  )
}

export default ProjectItem