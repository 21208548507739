import React from 'react'

import {Link} from 'react-router-dom'

import { BsLinkedin, BsGithub} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'
import {BiPhoneCall} from 'react-icons/bi'

import SkillList from '../components/UI/SkillList'

import './Home.css'

const Home = () => {
  return (
    <div className='home'>
        <div className='about'>
            <h2>Hi, My Name is Surinder Singh</h2>
            <div className='prompt'>
                <p>I am a full stack web developer and a software Engineer who is passionate about new technologies and servies
                    and Obsessed with solving problems and making better projects for customers and Team.</p>
                    <Link to="https://www.linkedin.com/in/surindersinghs/">
                        <BsLinkedin  />
                        </Link>
                    <Link to="https://github.com/SurinderSinghSaby">
                        <BsGithub />
                    </Link>
                    <a href={`tel:${5204614333}`}>
                        <BiPhoneCall />
                        </a>
                        <a href="mailto:sabysurinder@gmail.com">
                        <AiOutlineMail />
                        </a>

            </div>
        </div>
        <SkillList/>
    </div>
  )
}

export default Home