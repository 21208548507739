import React from 'react'

import ProjectItem from '../components/ProjectUI/ProjectItem'
import {ProjectList} from '../helpers/ProjectList'
import './Projects.css'

const Project = () => {
  return (
    <div className='projects'>
        <h1>My Personal Projects</h1>
        <div className='projectList'>
            {ProjectList.map((project, id) => {
                return <ProjectItem
                 title={project.title} 
                 image={project.image} 
                 id= {project.id}
                 http={project.http}
                 />
            })}
        </div>
        
    </div>
  )
}

export default Project