import React from 'react'

import Skills from './Skills'

import '../../pages/Home.css'

const SkillList = () => {
  return (
    <div className="skills">
            <h1>
                Skills
            </h1>
    <ol className='list'>
        <Skills
            heading="Front-End"
            info="HTML, CSS, JavaScript, React, Redux, Bootstrap, Material UI, Tailwind CSS"
        />
        <Skills
            heading="Back-End"
            info="Node.js, Express, MongoDB, Firebase, REST API, GraphQL, Apollo, JWT, OAuth"
        />
        <Skills
            heading="Software-Engineering"
            info="MIPS, Hascell, Racket, C, C++, Python, Java"
        />
    </ol>
    </div>
  )
}

export default SkillList