import React from 'react'
import { Outlet  } from "react-router-dom"

import Navbar from '../components/Navigation/Navbar'
import Footer from '../components/UI/Footer'

const Main = () => {
  return (
    <>
        <Navbar/>
        <Outlet/>
        <Footer/>
    </>
  )
}

export default Main