import React from 'react'

import {VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FaSchool} from 'react-icons/fa';


const TimeLine = (props) => {
  return (
    <VerticalTimelineElement
         className='vertical-timeline-element--eduction'
            date={props.date}
            iconStyle={{ background: '#3e497a', color: '#fff' }}
            icon={<FaSchool/>}
         >
          <h3 className='vertical-timeline-element-title'>
            {props.title}
          </h3>
          <p>{props.detail}</p>
        </VerticalTimelineElement>
  )
}

export default TimeLine