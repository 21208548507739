import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Home, Main, Project, ProjectDisplay, Experience, About, Contact } from './pages/index';

const App = () => {
  return (
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<Home />} />
            <Route path="project" element={<Project />} />
            <Route path="experience" element={<Experience />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="project/:id" element={<ProjectDisplay />} />
          </Route>
        </Routes>
      </HashRouter>
    </React.StrictMode>
  );
};

export default App;
