import React, {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {GiHamburgerMenu} from 'react-icons/gi'
import './Navbar.css'

const Navbar = () => {

    const [navbar, setNavbar] = useState(false)
    const location = useLocation()

    const toggleNavbar = () => {
        setNavbar(prev => !prev);
      };

    useEffect(() => {
        if(navbar){
            toggleNavbar()
        }
        
    }, [location])
    
  return (



    <div className='navbar' id={navbar ? "open" : "close"}>

        <div className='toggleButton'>
            <button onClick={toggleNavbar} ><GiHamburgerMenu/></button>
        </div>
        <div className="links">
            <Link to='/'>Home</Link>
            <Link to='/project'>Project</Link>
            <Link to='/experience'>Experience</Link>
            <a href={process.env.PUBLIC_URL + '/Resume.pdf'} download="Resume.pdf">Resume</a>
            {/*<Link to='/about'>About</Link>
            <Link to='/contact'>Contact</Link>*/}

        </div>
    </div>
  )
}

export default Navbar