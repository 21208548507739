import React from 'react'

import {useParams} from 'react-router-dom'
import { ProjectList } from '../helpers/ProjectList';
import {BsGithub} from 'react-icons/bs'

import './ProjectDisplay.css'

const ProjectDispaly = () => {

  const {id} = useParams();
  const project = ProjectList[id]
  return (
    <div className='project'>
      <h1>{project.title}</h1>
      <h2>{project.tech}</h2>
      <img src={project.image} alt={project.title} />
      <p>
        <b>Description: </b>{project.description}</p>
      <BsGithub />
    </div>
  )
}

export default ProjectDispaly