import React from 'react';

import TimeLineList from '../components/TimeLine/TimeLineList';

const Experience = () => {
  return (
    <>
        <TimeLineList />
    </>
  );
};

export default Experience;
