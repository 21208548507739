import React from 'react'

import { VerticalTimeline} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import TimeLine from './TimeLine'

const TimeLineList = () => {
  return (
    <div className='experience'>
      <VerticalTimeline lineColor='#3e497a'>
        <TimeLine 
        title="University of Arizona"
        detail="Bachelor of Science in Computer Science"
        date="2020-2024" 
        />
        <TimeLine 
        title="BASS IT"
        detail="IT Senior Student"
        date="2020-2023" 
        />
        <TimeLine 
        title="BASS IT"
        detail="IT Student App Developer"
        date="2023-Current" 
        />
      </VerticalTimeline>
      </div>
  )
}

export default TimeLineList